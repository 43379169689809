<template>
  <div style="height: inherit">
    <v-style>
      @media screen and (max-width: 599px) {
        .v-timeline:after{
          height: calc(100% - {{dtimelineMemberLvl>=1 ?( (dtimelineMemberLvl) * 85) + 40 : 0 }}px) !important;
          top : {{dtimelineMemberLvl>=1 ?( (dtimelineMemberLvl)*85)+40:0}}px !important;
        }

<!--        .v-timeline:after{-->
<!--          {{ timelineHeight }}-->
<!--          {{$data._top}}-->
<!--        }-->

      }
      @media screen and (min-width:600px) and (max-width: 959px) {
        .v-timeline:after{
        height: calc(100% - {{dtimelineMemberLvl>=1 ?( (dtimelineMemberLvl) * 110) + 50 : 0 }}px) !important;
        top : {{dtimelineMemberLvl>=1 ?( (dtimelineMemberLvl )*110)+50:0}}px !important;
        }
      }
    </v-style>
    <v-snackbar class="pa-0" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <v-card class="vip-card justify-center">
      <v-row no-gutters justify="center" class="hidden-md-and-down" :class="$vuetify.breakpoint.mdAndUp ? 'mt-16 mb-8' : 'mx-2 mt-8 mb-8'">
        <v-col cols="12" md="6" lg="5">
          <v-card class="vip-progress-card mt-8" elevation="0" v-if="isLoggedIn">
            <v-row no-gutters align="end" class="px-8 pt-4">
              <v-col>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="text-left mb-1 ml-2">
                    <label class="white--text font-weight-bold" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1' : 'title'">{{ vipProgress.current_level_name }}</label>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="1" md="1" class="text-left">
                    <!-- <v-avatar :size="$vuetify.breakpoint.xsOnly ? 50 : 64" class="vip-process-bar-icon">
                      <img src="/static/image/icon/vip-level-icon.png" />
                    </v-avatar> -->
                    <v-avatar class="vip-process-bar-icon" :size="$vuetify.breakpoint.xsOnly ? '50' : '64'">
                      <img src="/static/image/icon/vip-level-no.png" />
                      <span class="centered white--text">{{ currentLvlCode }}</span>
                    </v-avatar>
                  </v-col>
                  <v-col cols="11" md="11">
                    <v-progress-linear :height="$vuetify.breakpoint.xsOnly ? 35 : 45" class="vip-progress-bar" :value="vipProgressPercentage">
                      <template v-slot:default="{ value }">
                        <label v-if="vipProgress.member_progress_type == 2">{{ vipProgress.current_deposit_progress }} / {{ vipProgress.required_deposit_amount }}</label>
                        <label v-if="vipProgress.member_progress_type == 3">{{ vipProgress.current_rollover_progress }} / {{ vipProgress.required_account_rollover }}</label>
                      </template>
                    </v-progress-linear>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters class="px-8 pt-4">
              <v-col cols="6" class="mb-6" :class="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xlOnly ? 'pr-10' : 'pr-5') : 'pr-2'">
                <app-button class="ml-1 dialog-button" :disabled="!isApplicable" :action="applyVipBonus" :title="$t(`button.vipSpecial`) + bonusPercentage"></app-button>
              </v-col>
              <v-col class="text-center" cols="6" :class="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xlOnly ? 'pl-10' : 'pl-5') : 'pl-2'">
                <app-button class="dialog-button mr-2" :disabled="!vipProgress.has_pending_claim" :action="claimVipProgress" :title="$t(`button.claimVip`)"></app-button>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="vip-progress-card mt-8" elevation="0" v-else>
            <v-row no-gutters align="end" class="px-8 pt-4">
              <v-col>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="text-center">
                    <label class="white--text font-weight-bold" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1' : 'title'">
                      {{ $t(`label.vipLogin`) }}
                    </label>
                  </v-col>
                  <v-col cols="12" class="text-center my-8">
                    <label class="white--text font-weight-bold subtitle-1">{{ $t(`label.vipLoginBenefit`) }}</label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="6" sm="5" class="mb-6 text-center">
                    <v-btn
                      height="auto"
                      @click="this.goToLoginPage"
                      color="black"
                      class="mr-6 primary-button pa-2 font-weight-bold yellow--text"
                      :class="$vuetify.breakpoint.xsOnly ? 'vip-mobile-login-button' : 'embedded-login-button'"
                    >
                      {{ $t(`button.login`) }}
                    </v-btn>
                  </v-col>
                  <v-col class="text-center" sm="5" cols="6">
                    <app-button
                      :customClass="'buttonPrimary subtitle-1 full-width font-weight-bold pa-2 theme-button embedded-register-button'"
                      :action="this.goToRegisterPage"
                      :title="$t(`label.signUp`)"
                    ></app-button>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!--      desktop view-->
      <div v-if="$vuetify.breakpoint.smAndUp" class="hidden-sm-and-down" :class="$vuetify.breakpoint.mdAndUp ? 'mt-16 mb-8' : 'mx-2 mt-16 mb-8'">
        <v-card style="background: transparent !important">
          <v-card class="vip-summary-table white--text" elevation="0">
            <v-row align="center" no-gutters>
              <v-col cols="2" :class="$vuetify.breakpoint.xsOnly ? 'pl-3' : 'pl-8'">
                {{ $t(`label.level`) }}
              </v-col>
              <v-col class="text-center content" :class="currentLvlCode == reward.level ? 'selected-level' : ''" cols="1" v-for="reward in currrentVipSummary" :key="reward.level">
                <v-avatar v-if="currentLvlCode != reward.level" :size="$vuetify.breakpoint.xsOnly ? '30' : '48'">
                  <img src="/static/image/icon/vip-level-no.png" />
                  <span class="centered">{{ reward.level }}</span>
                </v-avatar>
                <v-avatar v-else :size="$vuetify.breakpoint.xsOnly ? '30' : '48'">
                  <img src="/static/image/icon/vip-level-icon.png" />
                </v-avatar>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="vip-summary-table-content white--text" elevation="0">
            <v-row align="center" style="border-bottom: 0.5px solid white;" no-gutters>
              <v-col cols="2" :class="$vuetify.breakpoint.xsOnly ? 'pl-3' : 'pl-8'">
                {{ $t(`label.rewards`) }}
              </v-col>
              <v-col
                cols="1"
                style="display:flex;min-height:150px;vertical-align: middle;justify-content: center;"
                :class="currentLvlCode == reward.level ? 'selected-level content' : 'content'"
                v-for="reward in currrentVipSummary"
                :key="reward.level"
              >
                <v-row style="align-self: center;" no-gutters>
                  <v-col cols="12" class="text-center">
                    <img class="icon-size mb-2" :src="getDisplayIcon(reward)" />
                  </v-col>
                  <v-col cols="12" class="text-center" v-if="reward.reward_type == 'COINS'">
                    {{ $t(`label.free`) }}
                    <br />
                    {{ currencySymbolMapp(currentCurrency) }}{{ reward.text }}
                  </v-col>
                  <v-col cols="12" class="text-center" v-if="reward.reward_type == 'BONUS'">
                    {{ reward.text }}
                    <br />
                    {{ $t(`label.bonus`) }}
                  </v-col>
                  <v-col cols="12" class="text-center" v-if="reward.reward_type == 'GIFT'">
                    {{ $t(`label.free`) }}
                    <br />
                    {{ reward.text }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" no-gutters>
              <v-col cols="2" :class="$vuetify.breakpoint.xsOnly ? 'pl-3' : 'pl-8'">
                {{ $t(`label.requirement`) }}
              </v-col>
              <v-col cols="1" :class="currentLvlCode == reward.level ? 'selected-level px-1 py-2' : ''" class="text-center content" v-for="reward in currrentVipSummary" :key="reward.level">
                <app-button
                  :custom-class="'buttonPrimary theme-button theme--light subtitle-1 pa-1'"
                  v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level"
                  :action="claimVipProgress"
                  :title="$t(`label.claim`)"
                ></app-button>
                <span v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </div>
      <div v-else class="mx-2 mt-8 mb-8 vip-summary-container hidden-sm-and-down">
        <v-row align="center" no-gutters class="d-flex flex-nowrap">
          <v-col cols="3">
            <v-card class="vip-summary-table-mobile border-top-left-radius white--text pl-3" style="height:38px" elevation="0">
              {{ $t(`label.level`) }}
            </v-card>
          </v-col>
          <v-col cols="3" class="text-center" v-for="(reward, index) in currrentVipSummary" :key="index">
            <v-card
              class="vip-summary-table-mobile white--text"
              :class="
                currentLvlCode == reward.level
                  ? index == currrentVipSummary.length - 1
                    ? 'selected-level border-top-right-radius'
                    : 'selected-level'
                  : index == currrentVipSummary.length - 1
                  ? 'border-top-right-radius'
                  : ''
              "
              elevation="0"
            >
              <v-avatar v-if="currentLvlCode != reward.level" :size="$vuetify.breakpoint.xsOnly ? '30' : '48'">
                <img src="/static/image/icon/vip-level-no.png" />
                <span class="centered">{{ reward.level }}</span>
              </v-avatar>
              <v-avatar v-else :size="$vuetify.breakpoint.xsOnly ? '30' : '48'">
                <img src="/static/image/icon/vip-level-icon.png" />
              </v-avatar>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" class="flex-nowrap" style="border-bottom: 0.5px solid white;" no-gutters>
          <v-col cols="3">
            <v-card style="height:83px" class="vip-summary-table-content-mobile white--text pl-3" elevation="0">
              {{ $t(`label.rewards`) }}
            </v-card>
          </v-col>
          <v-col cols="3" v-for="reward in currrentVipSummary" :key="reward.level">
            <v-card class="vip-summary-table-content-mobile white--text" :class="currentLvlCode == reward.level ? 'selected-level content' : 'content'" elevation="0">
              <v-row no-gutters>
                <v-col cols="12" class="text-center">
                  <img class="icon-size mb-2" :src="getDisplayIcon(reward)" />
                </v-col>
                <v-col cols="12" class="text-center" v-if="reward.reward_type == 'COINS'">
                  {{ $t(`label.free`) }}
                  <br />
                  {{ currencySymbolMapp(currentCurrency) }}{{ reward.text }}
                </v-col>
                <v-col cols="12" class="text-center" v-if="reward.reward_type == 'BONUS'">
                  {{ reward.text }}%
                  <br />
                  {{ $t(`label.bonus`) }}
                </v-col>
                <v-col cols="12" class="text-center" v-if="reward.reward_type == 'GIFT'">
                  {{ $t(`label.free`) }}
                  <br />
                  {{ reward.text }}
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" class="flex-nowrap" no-gutters>
          <v-col cols="3">
            <v-card height="46" class="vip-summary-table-content-mobile white--text border-bottom-left-radius pl-3" elevation="0">
              {{ $t(`label.requirement`) }}
            </v-card>
          </v-col>
          <v-col cols="3" v-for="(reward, index) in currrentVipSummary" :key="index">
            <v-card
              align="center"
              class="vip-summary-table-content-mobile text-center white--text"
              height="46"
              :class="
                currentLvlCode == reward.level
                  ? index == currrentVipSummary.length - 1
                    ? 'selected-level border-bottom-right-radius'
                    : 'selected-level'
                  : index == currrentVipSummary.length - 1
                  ? 'border-bottom-right-radius'
                  : ''
              "
              elevation="0"
            >
              <app-button class="pa-1" v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level" :action="claimVipProgress" :title="$t(`label.claim`)"></app-button>
              <span v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!--      mobile view-->
      <v-img class="hidden-md-and-up mobile-bettingPass-banner"
             :src="`/static/image/mobileBanner/BB88_BP.jpg`" :aspect-ratio="900/280"
      ></v-img>
      <v-row class="hidden-md-and-up mobile-vip-header">
        <div class="mobile-progress-header-div">
          <v-card class="mobile-lvl-card" cols="4" style="flex-grow: 3">
            <!--            <v-card-subtitle style="color: #FFFFFF">{{ currentLvlCode }}</v-card-subtitle>-->
            Level
            <v-card-title class="justify-center lvl-title">{{currentLvlCode}}</v-card-title>

          </v-card>
          <v-progress-linear :height="35" cols="6" class="vip-progress-bar" :value="vipProgressPercentage" style="flex-grow:6"></v-progress-linear>
          <a  :href="`#item_${currentLvlCode}`" style="text-decoration: unset;">Go to My Level</a>
        </div>
        </v-col>
      </v-row>
      <v-row class="hidden-md-and-up justify-center no-gutters" style="margin-top:20px">
        <v-col class="vip-timeline-wrapper">
          <v-timeline>
            <v-timeline-item v-for="(item, i) in currrentVipSummary" :key="i" :id="`item_` + (item.level + 1).toString()" right>
              <template v-slot:icon>
                <!--                <v-avatar tile>-->
                <!--                  <span>prize name</span>-->
                <!--                </v-avatar>-->
                <v-card class="prizename-card text-center" :class="[item.level <= currentLvlCode ? 'fulfilled' : '']">
                  <span class="prizename-span" v-if="item.reward_type == 'COINS'">
                    {{ $t(`label.free`) }} {{ currencySymbolMapp(currentCurrency) }}
                    {{ item.text }}
                  </span>
                  <span class="prizename-span" v-if="item.reward_type == 'BONUS'">{{ item.text }} {{ $t(`label.bonus`) }}</span>
                  <span class="prizename-span" v-if="item.reward_type == 'GIFT'">{{ $t(`label.free`) }} {{ item.text }}</span>
                </v-card>
              </template>

              <template v-slot:opposite>
                <!--                <v-card class="prizeImg text-center">-->
                <!--                  prize image-->
                <!--                </v-card>-->
                <v-card class="prizeImg" :class="[item.level <= currentLvlCode ? 'fulfilled' : '']">
                  <img class="icon-size resetInclineStyle" :src="getDisplayIcon(item)" :class="[item.reward_type == 'GIFT' ? 'giftPrize-size' : '']" />
                  <span class="prizeIndex-span">{{ item.level }}</span>
                </v-card>
              </template>
              <!-- claimable button action-->
              <div v-if="vipProgress.has_pending_claim && currentLvlCode == item.level"
                class="claim-btn text-center "
                :class="[item.level <= currentLvlCode ? 'fulfilled fulfilled-claimBtn' : '', vipProgress.has_pending_claim && currentLvlCode == item.level ? 'currentLvl-claimBtn' : '']"
                   @click="claimVipProgress"
              >
                <span class="resetInclineStyle">Claim Prize</span>
              </div>
              <div v-else
                   class="claim-btn text-center "
                   :class="[item.level <= currentLvlCode ? 'fulfilled fulfilled-claimBtn' : '', ]"
              >
                <span class="resetInclineStyle">Claim Prize</span>
              </div>

              <!--              <v-card class="claim-btn text-center">-->
              <!--                <v-card-text>claim</v-card-text>-->
              <!--              </v-card>-->
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
      <v-row no-gutters style="
    padding-bottom: 70px;
">
        <v-col cols="12" class="text-center mb-md-6" v-if="pagination.totalPage > 1">
          <v-pagination
            dark
            circle
            class="vip_summary_pagination"
            v-model="pagination.currentPage"
            :length="pagination.totalPage"
            @input="changePage"
            :next-icon="'arrow_forward_ios'"
            :prev-icon="'arrow_back_ios'"
            :total-visible="pagination.paginationPageLimit"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { BONUS_LIST, VIP_BONUS_APPLY, VIP_BONUS_CHECK, VIP_BONUS_RESET, VIP_CLAIM_REWARD, VIP_RESET_CLAIM, VIP_REWARDS_SUMMARY, VOUCHER_BONUS_CHECK } from '../../store/bonus.module'
import { MEMBER_GET_VIP_PROGRESS } from '../../store/member.module'
import { VipClaimType } from '../../constants/enums'
import { ROUTE_NAME } from '../../constants/route.constants'
import { locale, errorCodeHelper, uiHelper } from '@/util'
import currencySymbolMapp from 'currency-symbol-map'
import { SHARED } from '@/constants/constants'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'

export default {
  name: 'vipPage',
  data: () => ({
    bonusPercentage: '',
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    },
    currrentVipSummary: [],
    searchCriteria: {
      pageNumber: 1,
      pageSize: 10,
      mobilePageSize: 10
    },
    currentCurrency: uiHelper.getCurrency(),
    currencySymbolMapp: currencySymbolMapp,
    alertShow: false,
    alertSuccess: true,
    alertMessage: '',
    testPendingClaim: false,
    dtimelineMemberLvl:0
  }),
  components: {
    AppLoginEmbedded
  },
  computed: {
    timelineHeight(){
      return this._height;
    },
    bonusList() {
      return this.$store.state.bonus.bonusList
    },
    isApplicable() {
      return this.$store.state.bonus.vipBonusCheckResult.data.available
    },
    applyComplete() {
      return this.$store.state.bonus.vipBonusApplyResult.complete
    },
    vipProgress() {
      return this.$store.state.member.vipProgress
    },
    vipClaimComplete() {
      return this.$store.state.bonus.vipProgressClaimResult.complete
    },
    vipRewardsSummaryData() {
      return this.$store.state.bonus.vipRewardsSummary
    },
    vipClaimType() {
      switch (this.vipProgress.member_progress_type) {
        case VipClaimType.BOTH:
          return 'Rollover and Deposit'
        case VipClaimType.DEPOSIT_ONLY:
          return 'Deposit Only'
        case VipClaimType.ROLLOVER_ONLY:
          return 'Rollover Only'
      }
    },
    vipProgressPercentage() {
      if (this.vipProgress.member_progress_type == 2) {
        return (this.vipProgress.current_deposit_progress / this.vipProgress.required_deposit_amount) * 100
      } else if (this.vipProgress.member_progress_type == 3) {
        return (this.vipProgress.current_rollover_progress / this.vipProgress.required_account_rollover) * 100
      }
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    currentLvlCode() {
      this.dMemberLvl = parseInt(this.vipProgress.current_level_name.split(' ')[1])
      return this.vipProgress.current_level_name.split(' ')[1]
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  watch: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    bonusList() {
      try {
        let bonusList = _.cloneDeep(this.$store.state.bonus.bonusList)
        let currentMemberGroup = this.vipProgress.current_level_name
        if (typeof bonusList != 'undefined' && bonusList.length > 0) {
          let releaseTierBonus = bonusList.find(x => x.useReleaseTiers == 1)
          if (releaseTierBonus != null) {
            let selectedApplyBonusMemberGroup = releaseTierBonus.memberGroup.find(x => x.member_group.toLowerCase() == currentMemberGroup.toLowerCase())
            if (selectedApplyBonusMemberGroup != null) {
              this.bonusPercentage = '(' + selectedApplyBonusMemberGroup.percentage_given + '%' + ')'
            }
          }
        }
      } catch (err) {
        this.bonusPercentage = ''
      }
    },
    applyComplete() {
      if (this.$store.state.bonus.vipBonusApplyResult.success) {
        window.alert('VIP Bonus apply status: ' + this.$store.state.bonus.vipBonusApplyResult.success)
        this.$store.dispatch(`${VIP_BONUS_RESET}`)
        this.getVipApplicability()
      }
    },
    vipClaimComplete() {
      if (this.$store.state.bonus.vipProgressClaimResult.complete) {
        this.showVipClaimProgress(this.$store.state.bonus.vipProgressClaimResult)
      }
    },
    vipRewardsSummaryData() {
      this.renderPage()
      // this.changePage(1)
    },
    alertShow() {
      if (!this.alertShow) {
        this.alertSuccess = ''
        this.alertMessage = ''
      }
    }
  },
  created() {
    this.metaTag()
    this.getVipApplicability()
    this.getVipProgress()
    this.getVipRewardsSummary()
    this.getAvailableBonus()
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href;
      const domainWithProtocol = currentUrl.split('/')[2];
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '');
      const path = window.location.pathname;
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '');
      const Links = `https://${domain}${cleanPath}`;
      const Linksen = `https://${domain}/en-BD${cleanPath}`;
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`;
      const Linksenin = `https://${domain}/en-IN${cleanPath}`;
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`;
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`;
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`;

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    calculateTimelineColor(targetPage){
      let vipLevel = parseInt(this.currentLvlCode);
      let colorLevel = vipLevel % 10;
      let memberLvlPage =Math.ceil(vipLevel/10);

       //over lv 10 , > first page
        if(colorLevel==0 && memberLvlPage==targetPage){
          //current page last item/lvl = full page color line
          this.dtimelineMemberLvl = this.dtimelineMemberLvl
        }else{
          //x.1 - x.9 , y = 1 - 9
          // x page , y level
          if(memberLvlPage==targetPage){
            //member lvl within curreng page's lv ,display target lvl color line
            this.dtimelineMemberLvl = colorLevel
          }else if(memberLvlPage<targetPage){
            //over member lvl
            this.dtimelineMemberLvl=0
          }else if(memberLvlPage>targetPage){
            //memberlvl higher than current page's lv = full page color line
            this.dtimelineMemberLvl=11
          }
        }


    },
    getAvailableBonus() {
      if (this.isLoggedIn) {
        this.$store.dispatch(`${BONUS_LIST}`)
      }
    },
    getVipApplicability() {
      if (this.isLoggedIn) {
        this.$store.dispatch(`${VIP_BONUS_CHECK}`)
      }
    },
    getVipProgress() {
      if (this.isLoggedIn) {
        this.$store.dispatch(MEMBER_GET_VIP_PROGRESS)
      }
    },
    getVipRewardsSummary() {
      this.$store.dispatch(VIP_REWARDS_SUMMARY)
    },
    renderPage() {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.pagination.totalPage = Math.ceil(this.vipRewardsSummaryData.length / this.searchCriteria.mobilePageSize)
      } else {
        this.pagination.totalPage = Math.ceil(this.vipRewardsSummaryData.length / this.searchCriteria.pageSize)
      }
      this.searchCriteria.pageNumber = 1
      this.changePage(1)
    },
    changePage(targetPage) {

      this.calculateTimelineColor(targetPage)

      this.searchCriteria.pageNumber = targetPage
      if (this.$vuetify.breakpoint.xsOnly) {
        this.currrentVipSummary = this.vipRewardsSummaryData.slice(
          (this.searchCriteria.pageNumber - 1) * this.searchCriteria.mobilePageSize,
          this.searchCriteria.pageNumber * this.searchCriteria.mobilePageSize
        )
      } else {
        this.currrentVipSummary = this.vipRewardsSummaryData.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.pageSize, this.searchCriteria.pageNumber * this.searchCriteria.pageSize)
      }
    },
    applyVipBonus() {
      const selectedBonusId = this.$store.state.bonus.vipBonusCheckResult.data.bonusId
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT,
        params: {
          bonusId: selectedBonusId
        }
      })
    },
    claimVipProgress() {
      console.log('claim vip progress')
      this.getVipApplicability()
      if (this.isApplicable) {
        this.applyVipBonus()
      } else {
        this.dispatchVipProgress()
      }
    },
    dispatchVipProgress() {
      this.$store.dispatch(VIP_CLAIM_REWARD)
    },
    showVipClaimProgress(result) {
      if (result.success) {
        this.openAlert(true, locale.getMessage(`message.vipBonusSuccess`))
      } else {
        this.openAlert(false, locale.getMessage(`message.vipBonusFailed`))
      }
      this.$store.dispatch(VIP_RESET_CLAIM)
      this.getVipProgress()
      this.getVipApplicability()
    },
    getDisplayIcon(reward) {
      switch (reward.reward_type) {
        case 'COINS':
          return this.getCoinPath()
        case 'GIFT':
          return reward.reward_gift ? reward.reward_gift.imgUrl : ''
        case 'BONUS':
          return this.getGiftPath()
      }
    },
    // hard code asset path
    getCoinPath() {
      return 'https://jiliwin.9terawolf.com/cms/babuVip/vip-reward-icon.png'
    },
    getGiftPath() {
      return 'https://jiliwin.9terawolf.com/cms/babuVip/vip-reward-gift.png'
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER,
        params: { lang: this.$route.params.lang }
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN,
        params: { lang: this.$route.params.lang }
      })
    },
    reminderDialog() {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.Confirmation`)
      dialog.message.push(locale.getMessage(`message.vipSpecialBonusRedeemReminder`))
      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.dispatchVipProgress()
      })
      dialog.button.push({
        title: locale.getMessage(`button.cancel`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    }
  }
}
</script>

<style lang="scss">

.vip-card {
  .mobile-lvl-card{
    background-color:#000000;
    box-shadow: 0px 0px 4px var(--v-newMobilePrimary-base) !important;
    transform: skewX(-15deg);
    -webkit-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    -o-transform: skewX(-15deg);
    height: 50px;
    width: 75px;
    color: #FFFFFF;
    text-align: center;
    padding-top:5px;
    .lvl-title{
      padding:unset;
    }
  }
  .mobile-progress-header-div{
    display:flex;
    color:#FFFFFF;
    margin-left: 30px;
    margin-right: 20px;
    gap: 0px 10px;
    gap: 0px 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .v-timeline:after {
    bottom: 0;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 2px;
    left: calc(45% - 1px);
    background: #474747;
  }
  .resetInclineStyle {
    -webkit-transform: skewX(15deg);
    transform: skewX(15deg);
    -webkit-transform: skewX(15deg);
    -moz-transform: skewX(15deg);
    -ms-transform: skewX(15deg);
    -o-transform: skewX(15deg);
  }
  .fulfilled {
    box-shadow: 0px 0px 4px #ffce01 !important;
  }

  .fulfilled-claimBtn {
    background-color: var(--v-newMobilePrimary-base);
    color: #000000;
    opacity: 0.5;
  }

  .giftPrize-size {
    height: 4rem !important;
    margin-top: -22px;
  }

  .currentLvl-claimBtn {
    opacity: unset !important;
  }
  .claimable-btn {
    opacity: unset !important;
  }

  .mobile-vip-header {
    height: 100px;
    border-bottom: 2px solid var(--v-newMobilePrimary-base);
    //opacity: .5;
  }
  .v-timeline {
    height: max-content;
  }
  .theme--light.v-timeline::before {
    background-color: var(--v-newMobilePrimary-base);
    left: calc(45% - 1px) !important;
  }
  .v-timeline-item {
    padding-bottom: 50px !important;
  }
  .v-timeline-item__dot {
    background: transparent !important;
  }
  .v-timeline-item__dot {
    width: 200px;
    .v-timeline-item__inner-dot {
      width: inherit;
      margin:4px 0 0 4px;
      .prizename-card {
        background-color: #000000;
        color: #ffffff;
        width: inherit;
        height: 60px;
        border-radius: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: skewX(-15deg);
        -webkit-transform: skewX(-15deg);
        -moz-transform: skewX(-15deg);
        -ms-transform: skewX(-15deg);
        -o-transform: skewX(-15deg);
        .prizename-span {
          -webkit-transform: skewX(15deg);
        }
      }
    }
  }
  .prizeImg.v-card {
    width: 65px;
    height: 60px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: skewX(-15deg);
    -webkit-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    -o-transform: skewX(-15deg);
    color: var(--v-newMobilePrimary-base);
    flex-direction: column;
    .prizeIndex-span {
      width: 100%;
      margin-right: 15px;
    }
  }
}

.claim-btn {
  color: #9b9b9b;
  height: 60px;
  width: 113px;
  background-color: #474747;
  transform: skewX(-15deg);
  -webkit-transform: skewX(-15deg);
  -moz-transform: skewX(-15deg);
  -ms-transform: skewX(-15deg);
  -o-transform: skewX(-15deg);
  .v-card__text {
    padding: unset;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}

.vip-timeline-wrapper {
  display: flex;
  justify-content: center;
  height: 57vh;
  overflow-y: scroll;
  //margin: 15px 50px 15px 50px;
}
.profile-alert-success {
  .v-icon {
    color: #25b34b;
  }
}

.profile-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}

.vip-summary-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 100vw;

  .border-top-left-radius {
    border-top-left-radius: 10px !important;
  }

  .border-top-right-radius {
    border-top-right-radius: 10px !important;
  }

  .border-bottom-left-radius {
    border-bottom-left-radius: 10px !important;
  }

  .border-bottom-right-radius {
    border-bottom-right-radius: 10px !important;
  }
}

.vip-card {
  background-color: transparent !important;

  .vip-progress-card {
    background-color: #0c2aab;
    border-radius: 20px;

    .vip-progress-status-title {
      background-image: linear-gradient(to left, #fddd04, #fdb504);
      padding: 1px 4px;
    }
  }

  .icon-size {
    height: 2em;
  }

  .vip-process-bar-icon {
    border: 10px solid #262626;
    z-index: 1;

    img {
      padding: 6px;
    }
  }

  .vip-progress-bar {
    background-color: #acacac !important;
    border: 10px solid #262626;
    border-radius: 20px;
    margin-top: 8px;

    .v-progress-linear__background {
      background-color: #acacac !important;
      border-color: #acacac !important;
      opacity: 1 !important;
    }

    .v-progress-linear__determinate {
      background-image: linear-gradient(to left, #fddd04, #fdb504) !important;
    }
  }

  .vip-summary-table {
    background-color: #262626;
    border-radius: 20px 20px 0px 0px !important;

    .content {
      padding: 12px;
    }
  }

  .vip-summary-table-content {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0px 0px 20px 20px !important;

    .content {
      padding: 12px;
    }
  }

  .vip-summary-table-mobile {
    background-color: #262626;
    border-radius: 0px;
    padding: 4px;

    .content {
      padding: 4px;
    }
  }

  .vip-summary-table-content-mobile {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0px;
    padding: 8px 4px;

    .content {
      padding: 4px;
    }
  }

  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #acacac !important;
    color: rgba(0, 0, 0, 0.12) !important;
  }
}

.vip_summary_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 12px;
    }
  }

  .v-pagination__more {
    color: var(--v-newMobilePrimary-base);
  }
}

.selected-level {
  background-color: #0c2aab !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  position: relative;
  text-align: center;
}

.embedded-register-button {
  width: 120px !important;
  padding: 4px !important;
}

.embedded-login-button {
  width: 110px !important;
}

@media (max-width: 1024px) {
  .vip-card {
    font-size: 13px;
  }
}

@media (max-width: 959px) {

  .theme--light.v-pagination .v-pagination__navigation{
    background-color: transparent !important;
    color: #FFFFFF !important;
  }
  .theme--dark.v-pagination .v-pagination__navigation{
    background-color: transparent !important;
    color: #FFFFFF !important;
  }
  .mobile-vip-header {
    margin-top: unset !important;
  }
  .vip-card {
    height: inherit;
    .icon-size {
      height: 44px;
      //margin-bottom:30px;
    }
    .v-timeline:before {
      height: 95% !important;
      /*start yellow line after the first level box*/
      top: 30px !important;
    }
    .v-timeline-item__body {
      padding-left: 50px;
    }
    .v-timeline-item__opposite {
      padding-right: 50px;
    }

    .vip_summary_pagination .v-pagination li button {
      font-size: 1.2rem;
      height: 32px;
    }
  }

  .vip-card {
    background-color: #333333 !important;
  }
  .theme--dark.v-pagination .v-pagination__navigation {
    background-color: transparent;
  }
  .vip-mobile-login-button {
    width: 120px !important;
    height: 30px !important;
    font-size: 12px !important;
  }
  .vip-mobile-register-button {
    width: 120px !important;
  }

  //  mobile layout
  .v-pagination {
    margin: 15px 0px;
    li {
      button {
        border-radius: 50% !important;
        min-height: 31px !important;
        min-width: 31px !important;
        margin-right: 10px;
      }
    }
  }
  .theme--dark.v-pagination.v-pagination__item.v-pagination__item--active{
    background-color: #474747 !important;
    color: var(--v-newMobilePrimary-base) !important;
  }

  .v-pagination__item {
    background-color: #474747 !important;
    color: var(--v-newMobilePrimary-base) !important;
    &.v-pagination__item--active{
      background-color: var(--v-newMobilePrimary-base) !important;
      color: #000000 !important;
    }
  }
  .v-pagination__item.v-pagination__item--active {
    background-color: var(--v-newMobilePrimary-base) !important;
    color: #000000 !important;
  }
}
@media (orientation: landscape) {
  .vip-timeline-wrapper {
    height: 35vh;
  }
  .mobile-vip-header {
    height: 60px !important;
  }

  .theme--light.v-timeline::before {
    left: calc(50% - 25px) !important;
  }
  .v-timeline::after {
    left: calc(50% - 25px) !important;
  }
}

$timelineItemHeight_599: 55px;

@media (max-width: 599px) {
  .vip-card {
    font-size: 12px;

    .vip-progress-card {
      background-color: #0c2aab;
      border-radius: 20px;

      .vip-progress-status-title {
        background-image: linear-gradient(to left, #fddd04, #fdb504);
        padding: 1px 4px;
      }
    }

    .icon-size {
      height: 3.5em;
    }

    .vip-process-bar-icon {
      border: 8px solid #262626;
      z-index: 1;
    }

    .vip-progress-bar {
      background-color: #111111 !important;
      border: 8px solid #262626;
      border-radius: 20px;
      margin-top: 8px;

      .v-progress-linear__background {
        background-color: #111111 !important;
        border-color: #111111 !important;
        opacity: 1 !important;
      }

      .v-progress-linear__determinate {
        background-image: linear-gradient(to left, #fddd04, #fdb504) !important;
      }
    }

    .v-btn.dialog-button {
      width: inherit !important;
      height: auto !important;
      font-size: 12px !important;
    }

    .v-timeline-item__opposite {
      padding-right: 8px;
    }
    .v-timeline-item {
      padding-bottom: 30px !important;
    }

    .theme--light.v-timeline::before {
      background-color: var(--v-newMobilePrimary-base);
      left: calc(50% - 15px) !important;
    }
    .v-timeline::after {
      left: calc(50% - 15px) !important;
    }

    .v-timeline-item__body {
      padding-left: 15px;
    }
    .v-timeline-item__dot {
      width: 145px;
      .v-timeline-item__inner-dot {
        width: inherit;
        .prizename-card {
          width: inherit;
          height: $timelineItemHeight_599;
        }
      }
    }
    .prizeImg.v-card {
      height: $timelineItemHeight_599;
      .icon-size {
        height: 2.8rem;
      }
      .prizeIndex-span {
        font-size: 0.7rem;
      }
    }
    .claim-btn {
      height: $timelineItemHeight_599 !important;
      width: 90px !important;
    }
  }
}
</style>
